<template>
  <div class="templates-container">
    <router-link tag="div" class="template" :to="{ name: 'adminTemplateDesign', params: { id: 'new' } }">
      <div>Create Template</div>
      <div class="add-template">+</div>
    </router-link>

    <router-link
      tag="div"
      class="template"
      :to="{ name: 'adminTemplateDesign', params: { id: template._id } }"
      :key="template._id"
      v-for="template in $store.state.admin.templates"
    >
      <div>
        {{ template.name }}
      </div>
      <img :src="template.preview_image" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Templates',
};
</script>

<style lang="scss" scoped>
.templates-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .add-template {
    width: 180px;
    height: 320px;
    border: 1px dashed white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .template {
    display: grid;
    flex-direction: column;
    width: 200px;
    cursor: pointer;
    align-items: center;

    img {
      width: 100%;
      // max-width: 125px;
    }
  }
}
</style>
